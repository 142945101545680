@tailwind base;
@tailwind components;
@tailwind utilities;
@font-face {
  font-family: "Inter";
  src: url("/public/Inter-VariableFont_slnt\,wght.ttf") format("truetype");
}

::-webkit-scrollbar {
  width: 6px;
  height: 6px;
}

::-webkit-scrollbar-thumb {
  background-color: #444;
  border-radius: 3px;
}

::-webkit-scrollbar-thumb:hover {
  background-color: #555;
}

::-webkit-scrollbar-track {
  background-color: #ffffff11;
}
